import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer marginTop30px text-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_button, {
    onClick: $options.copyDatabase
  }, {
    default: _withCtx(() => [_createTextVNode("备份数据库")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    onClick: $options.leaveDatabaseSubmit
  }, {
    default: _withCtx(() => [_createTextVNode("执行数据库迁移")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_dialog, {
    title: "",
    modelValue: $data.dialogFormVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.dialogFormVisible = $event),
    class: "info-div600",
    style: {
      "padding": "24px",
      "text-align": "left"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "校验码",
      "label-width": "80px",
      class: "marginTop40"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.check_database_password,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.check_database_password = $event),
        placeholder: "请输入校验码",
        size: "large"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createElementVNode("button", {
      class: "subBtn",
      onClick: _cache[1] || (_cache[1] = (...args) => $options.copyDatabaseSubmit && $options.copyDatabaseSubmit(...args))
    }, "提交"), _createElementVNode("button", {
      class: "qxBtn",
      onClick: _cache[2] || (_cache[2] = (...args) => $options.closeDiv && $options.closeDiv(...args))
    }, "取消")])]),
    _: 1
  }, 8, ["modelValue"])]);
}