import { migrateDatabase, DoingDatabase } from "@/api/api";
export default {
  name: "datebase",
  data() {
    return {
      check_database_password: '',
      dialogFormVisible: false
    };
  },
  mounted() {},
  methods: {
    copyDatabase() {
      this.dialogFormVisible = true;
    },
    copyDatabaseSubmit() {
      this.$root.msgTalk('你确定要备份数据库文件吗?此操作将备份数据库，然后删除之前的迁移文件，创建新的空白迁移文件，需要你重新编辑迁移文件的内容', this, 'copyDatabaseImp');
    },
    copyDatabaseImp() {
      migrateDatabase({
        check_database_password: this.check_database_password
      }).then(res => {
        this.$root.msgResult(res);
      });
    },
    leaveDatabaseSubmit() {
      this.$root.msgTalk('你确定要迁移数据库吗?为了安全起见，迁移之前请备份好数据库', this, 'leaveDatabaseImp');
    },
    leaveDatabaseImp() {
      DoingDatabase().then(res => {
        this.$root.msgResult(res);
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
    }
  }
};